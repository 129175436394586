import React, { Component } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "./component/header/Header";
import Footer from "./component/footer/Footer";
import SliderOne from "./component/slider/SliderOne";
import ServiceTwo from "./elements/service/ServiceTwo";
import Testimonial from "./elements/Testimonial";
import About from "./component/HomeLayout/homeOne/About";
import Helmet from "./component/common/Helmet";

class MainDemo extends Component {
    render() {
        return (
            <div className="active-dark"> 
                <Helmet pageTitle="RavenFire Code" />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Slider Area */}
                <div className="slider-wrapper">
                    <SliderOne />
                </div>
                {/* End Slider Area */}

                {/* Start About Area */}
                <div className="about-area about-position-top pb--120 bg_color--1">
                    <About />
                </div>
                {/* End About Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--80 bg_image bg_image--3">
                   <div className="container">
                        <ServiceTwo />
                   </div>
                </div>
                {/* End Service Area */}

                {/* Start Testimonial Area */}
                <div className="rn-testimonial-area bg_color--5 ptb--120">
                    <div className="container">
                        <Testimonial />
                    </div>
                </div>
                {/* End Testimonial Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />
            </div>
        );
    }
}

export default MainDemo;
